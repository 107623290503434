<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="dadosSubstituicao.clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="warning" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>   
    
    <div >
        <form-wizard ref="fwSubsituicao" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-warning), 1)" :title="null" :subtitle="null" stepSize="sm" backButtonText="<" nextButtonText=">" finishButtonText="Substituir" @on-complete="substituir" @on-error="exibirMenssageErro">
            <tab-content :title="getTituloTab1" class="mb-5" icon="feather icon-truck" :before-change="validarPasso1">
                <!-- tab 1 content -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                            <vs-table :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." maxItems="500" search hoverFlat stripe v-model="veiculoSelecionado" @selected="selecionarVeiculo">
                            <template slot="header">
                                <h6 class="ml-2 mt-2" v-if="listaVeiculos.length > 0 "> {{listaVeiculos.length}} veículos</h6>                    
                            </template>
                            <template slot="thead">
                                <vs-th class="p-0">Veículo</vs-th>                    
                                <vs-th class="p-0">Placa</vs-th>                    
                                <vs-th class="p-0">Código</vs-th>
                                <vs-th class="p-0">Ativação</vs-th>                    
                                <vs-th class="p-0">Fidelização</vs-th>                    
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                                    <vs-td  class="p-0"><small>{{tr.marca}} {{tr.modelo}} </small> </vs-td>
                                    <vs-td  class="p-0"><small class="font-bold" style='font-family: Verdana, sans-serif;'> {{tr.placa}} </small></vs-td>
                                    <vs-td  class="p-0">
                                            <small class="font-bold">{{tr.codigoModulo}}</small>  <small>{{tr.marcaModulo}}</small>
                                    </vs-td>                        
                                    <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataAtivacao}}</small></vs-td>                       
                                    <vs-td class="p-0">
                                        <vx-tooltip :text="getRenovacao(tr)">   
                                            <small style='font-family: Verdana, sans-serif;' v-if="tr.prazoFidelizacao > 0">{{tr.prazoFidelizacao}} meses</small>
                                        </vx-tooltip>
                                        <vx-tooltip text="Fidelização concluída">   
                                            <feather-icon icon="CheckIcon" svgClasses="w-4 h-4 stroke-current text-success" v-if="tr.prazoFidelizacao === 0" />
                                        </vx-tooltip>
                                    </vs-td>                                                           
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>                
            </tab-content>

            <!-- tab 2 content -->
            <tab-content title="2. Dados do Rastreador" class="mb-5" icon="feather icon-tablet" :before-change="validarPasso2">
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 w-full">
                            <vx-card>
                                <h5> Módulo Anterior </h5>
                                <label class="vs-input--label ml-1">Marca</label>
                                <v-select                                               
                                    :options="CONSTANTE_MARCAS_MODULO" 
                                    v-model="dadosSubstituicao.moduloAnterior.marca"   
                                    :reduce="r => r.data"                                        
                                    class="w-full vs-input--label">
                                </v-select> 
                                <vs-input label="Código"  v-model="dadosSubstituicao.moduloAnterior.codigo" class="w-full mt-4 ml-1" />
                            </vx-card>
                        </div>
                        <div class="vx-col md:w-1/2 w-full">
                            <vx-card>
                                <h5> Módulo <span class="text-success">Novo</span> </h5>
                                <label class="vs-input--label ml-1">Marca</label>
                                <v-select                                               
                                    :options="CONSTANTE_MARCAS_MODULO" 
                                    v-model="dadosSubstituicao.moduloNovo.marca" 
                                    :reduce="r => r.data"                     
                                    class="w-full vs-input--label">
                                </v-select> 
                                <vs-input label="Serial"  v-model="dadosSubstituicao.moduloNovo.serial" class="w-full mt-4 ml-1" />
                                <vs-button class="ml-1 mt-2" size="small" @click="verificarDisponibilidade" :disabled="!dadosSubstituicao.moduloNovo.marca || !dadosSubstituicao.moduloNovo.serial" color="success">Verificar Disponibilidade</vs-button>
                            </vx-card>
                        </div>
                    </div>
            </tab-content>
        </form-wizard>
    </div>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { CONSTANTE_MARCAS_MODULO } from '@/constantesComboBox.js'

export default {   
    components: {
        FormWizard,
        TabContent
    }, 
    created() {
        this.CONSTANTE_MARCAS_MODULO = CONSTANTE_MARCAS_MODULO;
    },
    computed: {
        getTituloTab1() {
            if (this.veiculoSelecionado) {
                return "1. Veículo selecionado: " + this.veiculoSelecionado.placa;
            } else {
                return "1.Selecione o veículo.";
            }
        }
    },
    data() {
        return {            
            dadosSubstituicao: {
                moduloAnterior : { marca: null, codigo: null },
                moduloNovo : { marca: null, serial: null },
                moduloNovoDisponivel: false,
                veiculo: null,
                clienteSelecionado: null
            },
            listaClientes: [],
            listaVeiculos: [],              
            exibirMensagemNenhumDadoEncontrado: false,   
            veiculoSelecionado: null,                       
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        getRenovacao(tr) {
                if (tr.numeroRenovacao > 0) {
                    return "Contrato renovado: " + tr.numeroRenovacao;
                } else {
                    return "1a contratação";
                }
        },
        selecionarVeiculo(tr) {
            this.veiculoSelecionado = tr;
            this.dadosSubstituicao.veiculo = tr;
        },
        exibirMenssageErro: function(errorMsg){
            if (errorMsg) {
                this.$vs.notify({
                    title: "Erro", text: errorMsg, time: 10000,
                    iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            }
        },
        validarPasso1() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (self_.dadosSubstituicao.veiculo) {
                    self_.dadosSubstituicao.moduloNovoDisponivel = false;
                    return resolve(true);
                }
                return reject("Selecione um veículo");
            });
        },
        validarPasso2() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (self_.dadosSubstituicao.moduloAnterior.marca && self_.dadosSubstituicao.moduloAnterior.codigo 
                && self_.dadosSubstituicao.moduloNovo.marca && self_.dadosSubstituicao.moduloNovo.serial && self_.dadosSubstituicao.moduloNovoDisponivel) {                    
                    return resolve(true);
                }
                if (!self_.dadosSubstituicao.moduloAnterior.marca) {
                    return reject("Selecione a marca do módulo anterior");
                }
                if (!self_.dadosSubstituicao.moduloAnterior.codigo) {
                    return reject("Informe o código do módulo anterior");
                }
                if (!self_.dadosSubstituicao.moduloNovo.marca) {
                    return reject("Selecione a marca do módulo novo");
                }
                if (!self_.dadosSubstituicao.moduloNovo.serial) {
                    return reject("Informe o serial do módulo novo");
                }
                 if (!self_.dadosSubstituicao.moduloNovoDisponivel) {
                    return reject("Clique no botão para verificar a disponibilidade do módulo novo");
                }
                
                return reject("Erro no passo 2");
                
            });
        },
        substituir() {
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja substituir módulo '
                 + self_.dadosSubstituicao.moduloAnterior.marca + ' ' + self_.dadosSubstituicao.moduloAnterior.codigo 
                 + ' do veículo ' +  self_.dadosSubstituicao.veiculo.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.substituirRequest
            });
        },
        substituirRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.dadosSubstituicao.veiculo.idVeiculo);   
            params.append('placa', this.dadosSubstituicao.veiculo.placa);   
            params.append('idCliente', this.dadosSubstituicao.clienteSelecionado.id);   

            params.append('codigoModuloAntigo', this.dadosSubstituicao.moduloAnterior.codigo);   
            params.append('marcaModuloAntigo', this.dadosSubstituicao.moduloAnterior.marca);   

            params.append('codigoModuloNovo', this.dadosSubstituicao.moduloNovo.codigo);   
            params.append('marcaModuloNovo', this.dadosSubstituicao.moduloNovo.marca);   
            params.append('numeroGSMNovo', this.dadosSubstituicao.moduloNovo.numeroGSM);   
            params.append('operadoraGSMNovo', this.dadosSubstituicao.moduloNovo.operadoraGSM);   
                    
            axios.post("/SubstituirModuloVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.statusMensagem === 0) {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        this.$refs.fwSubsituicao.reset();
                        this.dadosSubstituicao.moduloAnterior ={ marca: null, codigo: null };
                        this.dadosSubstituicao.moduloNovo ={ marca: null, serial: null };
                        this.dadosSubstituicao.moduloNovoDisponivel = false;
                        this.dadosSubstituicao.veiculo = null;
                        this.veiculoSelecionado = null;
                        this.fetchListaVeiculos();
                    } else {
                        this.$vs.notify({
                                title: "Erro", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    }
                    
                                       
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaVeiculos() {     
            if (!this.dadosSubstituicao.clienteSelecionado) {
                return;
            }
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.dadosSubstituicao.clienteSelecionado.CNPJCPF);
            
            axios.post("/RelatorioCliente", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                    this.exibirMensagemNenhumDadoEncontrado = this.listaVeiculos.length === 0;

                    if (this.listaVeiculos.length > 0 && this.dadosSubstituicao.veiculo) {
                        this.veiculoSelecionado = this.dadosSubstituicao.veiculo;
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }

            if (this.$store.state.AppActiveUser.perfil === "CONFIGURADOR") {
                params.append('monitoravelPorCentral', 'true');
                params.append('filter2', '12.934.518/0001-81');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        verificarDisponibilidade() {
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('serial', this.dadosSubstituicao.moduloNovo.serial);   
            params.append('marca', this.dadosSubstituicao.moduloNovo.marca);   
                    
            axios.post("/VerificarModuloPorSerialMarcaDisponivel", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.hasOwnProperty('mensagem')) {
                        this.dadosSubstituicao.moduloNovoDisponivel = false;
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    } else {
                        this.dadosSubstituicao.moduloNovoDisponivel = true;
                        this.dadosSubstituicao.moduloNovo = response.data;
                        this.$vs.notify({
                                title: "Mensagem", text: "Módulo disponível", 
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        
    },
    mounted() {               
        this.fetchListaClientes();

        if (this.$store.getters.dadosBackendSubstituicao) {
           this.dadosSubstituicao = JSON.parse(JSON.stringify(this.$store.getters.dadosBackendSubstituicao));
           this.fetchListaVeiculos();
        }
    },
    beforeDestroy() {    
        this.$store.dispatch('setDadosBackendSubstituicao', this.dadosSubstituicao);
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 420px);
      position: relative;      
    } 
</style>